import Layout from '../layouts'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  return (
    <Layout>
      <Seo
        title='Impressum'
        currentUrl='/impressum'
      />
      <Text text={`
__Unleashed Therapy__
David Witton
Grube Königsberg
35444 Biebertal

Verantwortlich: David Witton

david@unleashed-therapy.com

[+49 162 3979797](tel:+491623979797)

Für die Inhalte fremder Webseiten, auf welche von dieser Seite aus verlinkt wird, wird keinerlei Haftung übernommen.
Hierfür sind ausschließlich die Betreiber dieser Webseite verantwortlich.
Dies gilt im besonderen Maße auch für nachträgliche Änderungen, welche die Betreiber dieser Webseiten
an den Inhalten vornehmen, nachdem der Link auf diese gesetzt wurde.
      `}/>
      <Text text={`### Design & Development
  __UNKNOWN STUDIOS__
  Andre Zimpel
  Brühl 42
  09111 Chemnitz
      `}/>
    </Layout>
  )
}

export default Therapy
